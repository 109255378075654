* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // letter-spacing: 0.05em;

    -webkit-tap-highlight-color: transparent;
    // -ms-touch-action: manipulation; /* IE10  */
    // touch-action: manipulation; /* IE11+ */
}

html,
body {
    font-size: 10px;
    overflow-x: hidden;
    user-select: none;

    // -webkit-transform: translate3d(0, 0, 0); // safari bug fix
}

body {
    background-color: #f9fafc;
    color: #5e616e;
}

ul,
li {
    list-style: none;
}

a {
    text-decoration: none;
}

:root {
    --pb--black: #4e5055;
}

.clear-both {
    clear: both;
    display: inline-block;
    width: 100%;
}

.root {
    overflow-x: hidden;
}

.relative {
    position: relative;
}

@for $var from 1 to 25 {
    .col-#{$var} {
        width: 100% / (24 / $var);
    }
}

.right {
    float: right;
}

.vertical-moddle {
    vertical-align: middle;
}

.hypen {
    width: 100%;
    text-align: center;

    &::after {
        content: "";
        vertical-align: middle;
        display: inline-block;
        width: 10px;
        height: 1px;
        background-color: #5e616e;
    }
}

.number-overlap {
    margin-top: 8px;
}

#alert-portal {
    position: fixed;
    bottom: 12px;
    padding: 0 8px;
    width: 100%;
    z-index: 1000;
}

.required-approval {
    margin-top: 6px;
    font-size: 1.2rem;
    color: #ef6666;
}

.error {
    color: #ef6666;
}

.success {
    color: #00c853;
}

.text-overflow {
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
